@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.gradient-symmetry {
  background-color: #147a4b;
  background-image: linear-gradient(160deg, rgba(172, 255, 216, 0.6) 60%, rgba(208, 255, 238, 0.6) 100%); }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f4f3ef00;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(325deg, #4f57b7 0, #1c2135 0%);
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(31, 38, 135, 0); }

::-webkit-scrollbar {
  width: 0.2px;
  height: 0px; }

label {
  font-size: 12px;
  color: black;
  font-family: "Inter", "sans-serif"; }

.big-title-input > textarea {
  font-size: 24px !important;
  border-bottom: #b3b3b3; }

.yoda-animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }

/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Cube*/
.animated-cube {
  /* animation: hue-rotate 3s linear infinite; */ }

.login-cube {
  width: 100px; }

.chronos-cube-absolute {
  bottom: 0px;
  right: 0px; }

.login-cube-laptop {
  max-width: 500px; }

.chronos-login-logo {
  width: 400px;
  width: 300px; }

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0); }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg); } }

/* Logo*/
.otp-login-container .title-font {
  font-family: Poppins;
  font-weight: 700;
  color: #f2c0f2; }

.otp-login-container .caption-font {
  font-family: inter;
  font-size: 2em;
  font-weight: 700;
  color: white; }

.otp-login-container form {
  padding: 2rem;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 23.75rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  font-family: "Inter"; }

.otp-login-container input,
.otp-login-container textarea {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }

.otp-login-container input:focus,
.otp-login-container textarea:focus {
  outline: 0;
  border-bottom-color: #f2c0f2; }

.otp-login-container textarea {
  resize: vertical; }

.otp-login-container .btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  min-width: 160px; }

.otp-login-container .btn:focus,
.otp-login-container .btn:hover {
  background-color: #ebedf7 !important; }

.otp-login-container .btn:focus {
  outline: 0; }

.otp-login-container body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.otp-login-container .poppins {
  font-family: "Poppins", sans-serif; }

.otp-login-container .inter {
  font-family: "Inter", sans-serif; }

.purple-bg {
  background: linear-gradient(252.44deg, #4e1882 0%, #300964 100%), #9929ea;
  background-size: cover; }

.indigo-bg {
  background-color: #0d182b;
  /* background-image: linear-gradient(
    235deg,
    #4f57b7 0,
    #232968 50%,
    #1c2135 100%
  );
  background-size: cover; */ }

.otp-login-container .font-purple {
  color: #4e1882; }

.otp-login-container .light-purple-bg {
  background-color: #f2c0f2; }

.border-chronos {
  border-color: rgba(221, 214, 254, 0.15) !important; }

.glass-form {
  background: rgba(195, 206, 244, 0.15) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(195, 206, 244, 0.15) !important; }

/*Theme Colors*/
/*Borders*/
.side-nav-wrapper {
  max-width: 252px;
  width: 100%; }
  .side-nav-wrapper .side-nav {
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 100%);
    box-shadow: 0px 0px 80px 0px rgba(90, 144, 190, 0.3);
    max-width: 252px;
    width: 100%; }
    .side-nav-wrapper .side-nav .menuitem {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      color: #fff;
      padding: 8px; }
      .side-nav-wrapper .side-nav .menuitem:hover {
        background: rgba(48, 23, 9, 0.1);
        color: #0f1a2c;
        background: #fff;
        border-radius: 25px 0px 0px 25px; }
      .side-nav-wrapper .side-nav .menuitem.active {
        font-weight: 600;
        color: #0f1a2c;
        background: #fff;
        border-radius: 25px 0px 0px 25px; }

/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/* Main Theme Colors*/
.font-indigo-10 {
  color: #f6f8ff; }

.font-indigo-50 {
  color: #ebedf7; }

.font-indigo-100 {
  color: #f0f1f8; }

.font-indigo-200 {
  color: #e8ebff; }

.font-indigo-250 {
  color: #a4abcb; }

.font-indigo-300 {
  color: #8da1fc; }

.font-indigo-400 {
  color: #6775f5; }

.font-indigo-550 {
  color: #4f57b7; }

.font-indigo-650 {
  color: #1e2447; }

.font-indigo-700 {
  color: #384486; }

.bg-indigo-10 {
  background-color: #f6f8ff; }

.bg-indigo-50 {
  background-color: #ebedf7; }

.bg-indigo-100 {
  background-color: #f0f1f8; }

.bg-indigo-200 {
  background-color: #e8ebff; }

.bg-indigo-250 {
  background-color: #a4abcb; }

.bg-indigo-300 {
  background-color: #8da1fc; }

.bg-indigo-400 {
  background-color: #6775f5; }

.bg-indigo-550 {
  background-color: #4f57b7; }

.bg-indigo-650 {
  background-color: #1e2447; }

.bg-indigo-700 {
  background-color: #384486; }

.border-indigo-10 {
  border-color: #f6f8ff; }

.border-indigo-50 {
  border-color: #ebedf7; }

.border-indigo-100 {
  border-color: #f0f1f8; }

.border-indigo-200 {
  border-color: #e8ebff; }

.border-indigo-250 {
  border-color: #a4abcb; }

.border-indigo-300 {
  border-color: #8da1fc; }

.border-indigo-400 {
  border-color: #6775f5; }

.border-indigo-550 {
  border-color: #4f57b7; }

.border-indigo-650 {
  border-color: #1e2447; }

.border-indigo-700 {
  border-color: #384486; }

.font-gray-50 {
  color: #f8f8f9; }

.font-gray-60 {
  color: #f1f1f1; }

.font-gray-100 {
  color: #f4f4f4; }

.font-gray-150 {
  color: #e1e1e1; }

.font-gray-200 {
  color: #d8d8d8; }

.font-gray-250 {
  color: #a4a2a5; }

.font-gray-300 {
  color: #6d6d6d; }

.font-gray-350 {
  color: #747474; }

.font-gray-400 {
  color: #424242; }

.font-gray-500 {
  color: #332e35; }

.font-gray-600 {
  color: #323232; }

.font-gray-650 {
  color: #373739; }

.font-gray-900 {
  color: #2b2b2d; }

.font-gray-1000 {
  color: #171718; }

.bg-gray-50 {
  background-color: #f8f8f9; }

.bg-gray-60 {
  background-color: #f1f1f1; }

.bg-gray-100 {
  background-color: #f4f4f4; }

.bg-gray-150 {
  background-color: #e1e1e1; }

.bg-gray-200 {
  background-color: #d8d8d8; }

.bg-gray-250 {
  background-color: #a4a2a5; }

.bg-gray-300 {
  background-color: #6d6d6d; }

.bg-gray-350 {
  background-color: #747474; }

.bg-gray-400 {
  background-color: #424242; }

.bg-gray-500 {
  background-color: #332e35; }

.bg-gray-600 {
  background-color: #323232; }

.bg-gray-650 {
  background-color: #373739; }

.bg-gray-900 {
  background-color: #2b2b2d; }

.bg-gray-1000 {
  background-color: #171718; }

.border-gray-50 {
  border-color: #f8f8f9; }

.border-gray-60 {
  border-color: #f1f1f1; }

.border-gray-100 {
  border-color: #f4f4f4; }

.border-gray-150 {
  border-color: #e1e1e1; }

.border-gray-200 {
  border-color: #d8d8d8; }

.border-gray-250 {
  border-color: #a4a2a5; }

.border-gray-300 {
  border-color: #6d6d6d; }

.border-gray-350 {
  border-color: #747474; }

.border-gray-400 {
  border-color: #424242; }

.border-gray-500 {
  border-color: #332e35; }

.border-gray-600 {
  border-color: #323232; }

.border-gray-650 {
  border-color: #373739; }

.border-gray-900 {
  border-color: #2b2b2d; }

.border-gray-1000 {
  border-color: #171718; }

.font-yellow-50 {
  color: #fef6e9; }

.font-yellow-100 {
  color: #fde4be; }

.font-yellow-500 {
  color: #f7cd16; }

.font-yellow-550 {
  color: #f1c21b; }

.font-yellow-600 {
  color: #e0b808; }

.font-yellow-700 {
  color: #6b5f1c; }

.font-yellow-750 {
  color: #5b511b; }

.font-yellow-800 {
  color: #ba7507; }

.font-yellow-850 {
  color: #935d05; }

.font-yellow-900 {
  color: #532a01; }

.font-yellow-1000 {
  color: #301709; }

.bg-yellow-50 {
  background-color: #fef6e9; }

.bg-yellow-100 {
  background-color: #fde4be; }

.bg-yellow-500 {
  background-color: #f7cd16; }

.bg-yellow-550 {
  background-color: #f1c21b; }

.bg-yellow-600 {
  background-color: #e0b808; }

.bg-yellow-700 {
  background-color: #6b5f1c; }

.bg-yellow-750 {
  background-color: #5b511b; }

.bg-yellow-800 {
  background-color: #ba7507; }

.bg-yellow-850 {
  background-color: #935d05; }

.bg-yellow-900 {
  background-color: #532a01; }

.bg-yellow-1000 {
  background-color: #301709; }

.border-yellow-50 {
  border-color: #fef6e9; }

.border-yellow-100 {
  border-color: #fde4be; }

.border-yellow-500 {
  border-color: #f7cd16; }

.border-yellow-550 {
  border-color: #f1c21b; }

.border-yellow-600 {
  border-color: #e0b808; }

.border-yellow-700 {
  border-color: #6b5f1c; }

.border-yellow-750 {
  border-color: #5b511b; }

.border-yellow-800 {
  border-color: #ba7507; }

.border-yellow-850 {
  border-color: #935d05; }

.border-yellow-900 {
  border-color: #532a01; }

.border-yellow-1000 {
  border-color: #301709; }

.font-orange-50 {
  color: #fff1e8; }

.font-orange-100 {
  color: #ffd4bb; }

.font-orange-500 {
  color: #ff9000; }

.font-orange-600 {
  color: #db7c00; }

.font-orange-700 {
  color: #c44900; }

.font-orange-800 {
  color: #983800; }

.bg-orange-50 {
  background-color: #fff1e8; }

.bg-orange-100 {
  background-color: #ffd4bb; }

.bg-orange-500 {
  background-color: #ff9000; }

.bg-orange-600 {
  background-color: #db7c00; }

.bg-orange-700 {
  background-color: #c44900; }

.bg-orange-800 {
  background-color: #983800; }

.border-orange-50 {
  border-color: #fff1e8; }

.border-orange-100 {
  border-color: #ffd4bb; }

.border-orange-500 {
  border-color: #ff9000; }

.border-orange-600 {
  border-color: #db7c00; }

.border-orange-700 {
  border-color: #c44900; }

.border-orange-800 {
  border-color: #983800; }

.font-green-20 {
  color: #ebf8ec; }

.font-green-50 {
  color: #e7fdf4; }

.font-green-100 {
  color: #b6f9de; }

.font-green-500 {
  color: #0a8754; }

.font-green-550 {
  color: #6bd425; }

.font-green-600 {
  color: #086842; }

.bg-green-20 {
  background-color: #ebf8ec; }

.bg-green-50 {
  background-color: #e7fdf4; }

.bg-green-100 {
  background-color: #b6f9de; }

.bg-green-500 {
  background-color: #0a8754; }

.bg-green-550 {
  background-color: #6bd425; }

.bg-green-600 {
  background-color: #086842; }

.border-green-20 {
  border-color: #ebf8ec; }

.border-green-50 {
  border-color: #e7fdf4; }

.border-green-100 {
  border-color: #b6f9de; }

.border-green-500 {
  border-color: #0a8754; }

.border-green-550 {
  border-color: #6bd425; }

.border-green-600 {
  border-color: #086842; }

.font-blue-50 {
  color: #e8f0f8; }

.font-blue-100 {
  color: #b9d2e9; }

.font-blue-550 {
  color: #3778c6; }

.font-blue-700 {
  color: #13293d; }

.font-blue-800 {
  color: #0f2131; }

.bg-blue-50 {
  background-color: #e8f0f8; }

.bg-blue-100 {
  background-color: #b9d2e9; }

.bg-blue-550 {
  background-color: #3778c6; }

.bg-blue-700 {
  background-color: #13293d; }

.bg-blue-800 {
  background-color: #0f2131; }

.border-blue-50 {
  border-color: #e8f0f8; }

.border-blue-100 {
  border-color: #b9d2e9; }

.border-blue-550 {
  border-color: #3778c6; }

.border-blue-700 {
  border-color: #13293d; }

.border-blue-800 {
  border-color: #0f2131; }

.font-purple-100 {
  color: #f1eaf6; }

.font-purple-550 {
  color: #a346ce; }

.font-purple-600 {
  color: #4b355f; }

.font-purple-700 {
  color: #341e48; }

.bg-purple-100 {
  background-color: #f1eaf6; }

.bg-purple-550 {
  background-color: #a346ce; }

.bg-purple-600 {
  background-color: #4b355f; }

.bg-purple-700 {
  background-color: #341e48; }

.border-purple-100 {
  border-color: #f1eaf6; }

.border-purple-550 {
  border-color: #a346ce; }

.border-purple-600 {
  border-color: #4b355f; }

.border-purple-700 {
  border-color: #341e48; }

.font-red-100 {
  color: #fcf0f1; }

.font-red-150 {
  color: #ffe2df; }

.font-red-200 {
  color: #f7d1d4; }

.font-red-500 {
  color: #ed4c41; }

.font-red-550 {
  color: #db2b39; }

.font-red-600 {
  color: #c61e29; }

.font-red-700 {
  color: #af1e2a; }

.bg-red-100 {
  background-color: #fcf0f1; }

.bg-red-150 {
  background-color: #ffe2df; }

.bg-red-200 {
  background-color: #f7d1d4; }

.bg-red-500 {
  background-color: #ed4c41; }

.bg-red-550 {
  background-color: #db2b39; }

.bg-red-600 {
  background-color: #c61e29; }

.bg-red-700 {
  background-color: #af1e2a; }

.border-red-100 {
  border-color: #fcf0f1; }

.border-red-150 {
  border-color: #ffe2df; }

.border-red-200 {
  border-color: #f7d1d4; }

.border-red-500 {
  border-color: #ed4c41; }

.border-red-550 {
  border-color: #db2b39; }

.border-red-600 {
  border-color: #c61e29; }

.border-red-700 {
  border-color: #af1e2a; }

/*Theme Colors*/
/*Borders*/
.primary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: #384486;
  box-shadow: 0px 10px 20px -10px rgba(30, 36, 71, 0.5);
  border-radius: 4px; }
  .primary-cta:disabled {
    color: #e1e1e1; }
  .primary-cta:hover {
    background-color: #6775f5; }
  .primary-cta:active {
    background-color: #4f57b7; }
  .primary-cta:disabled {
    background: #f4f4f4;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5); }

.tertiary-red-cta {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #db2b39; }
  .tertiary-red-cta:disabled {
    color: #e1e1e1; }
  .tertiary-red-cta:hover {
    text-decoration: underline; }
  .tertiary-red-cta:active {
    text-decoration: underline;
    color: #a346ce; }

.secondary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #384486;
  border-radius: 4px;
  border: 2px solid #384486;
  color: #384486;
  font-weight: 600; }
  .secondary-cta:disabled {
    color: #e1e1e1; }
  .secondary-cta:hover {
    color: #6775f5;
    border: 3px solid #6775f5; }
  .secondary-cta:active {
    border: 2px solid #4f57b7;
    box-shadow: 0px 10px 20px -10px rgba(52, 30, 72, 0.5);
    color: #4f57b7; }
  .secondary-cta:disabled {
    border: 2px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25); }

/*Theme Colors*/
/*Borders*/
.shadow-container {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1); }

.query-box {
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 100%);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  /* Shades/50 */
  color: #ffffff;
  max-width: max-content; }
  .query-box.moksha {
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 100%);
    box-shadow: 0px 0px 80px 0px rgba(90, 144, 190, 0.3); }
  .query-box.moksha-wrapper {
    background: linear-gradient(134deg, rgba(124, 193, 222, 0.8) 0%, rgba(124, 193, 222, 0.8) 100%); }
  .query-box.wrapper {
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.45) 100%); }

.query-speak {
  background: radial-gradient(50% 50% at 50% 50%, #9b9fab 0%, #cbcdd1 100%);
  box-shadow: 0px 0px 80px 0px #5a90be, 0px 0px 48px 0px #7abfdd;
  backdrop-filter: blur(46px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 80px;
  color: #ffffff; }

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1); }
  to {
    opacity: 0;
    transform: scale3d(2, 2, 1); } }

.speaking {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 8px solid #fff;
  border-radius: 100%;
  background: transparent; }
  .speaking::after {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 1.5s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1; }

.app-bg {
  background: linear-gradient(0deg, #0f1a2c 0%, #0f1a2c 100%), lightgray 50%/cover no-repeat; }

.input-query {
  border-radius: 56px;
  background: linear-gradient(145deg, rgba(250, 250, 250, 0.4) 0%, rgba(250, 250, 250, 0.55) 100%); }
  .input-query.wrapper {
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.45) 100%); }

/*Theme Colors*/
/*Borders*/
.poppins {
  font-family: "Karla", sans-serif; }

.inter {
  font-family: "Lato", sans-serif; }

/*Theme Colors*/
/*Borders*/
.dot-flashing {
  background: linear-gradient(117.92deg, rgba(255, 255, 255, 0.24) 3.73%, rgba(255, 255, 255, 0.06) 96.65%);
  box-shadow: inset 1px 1px 100px 5px rgba(235, 237, 247, 0.3);
  backdrop-filter: blur(36.5px);
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 8px;
  color: #fff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s; }

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0; }

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 8px;
  background: linear-gradient(117.92deg, rgba(255, 255, 255, 0.24) 3.73%, rgba(255, 255, 255, 0.06) 96.65%);
  box-shadow: inset 1px 1px 100px 5px rgba(235, 237, 247, 0.3);
  backdrop-filter: blur(36.5px);
  color: #fff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s; }

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 8px;
  background: linear-gradient(117.92deg, rgba(255, 255, 255, 0.24) 3.73%, rgba(255, 255, 255, 0.06) 96.65%);
  box-shadow: inset 1px 1px 100px 5px rgba(235, 237, 247, 0.3);
  backdrop-filter: blur(36.5px);
  color: #fff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s; }

@keyframes dot-flashing {
  0% {
    background-color: #fff; }
  50%,
  100% {
    background-color: rgba(219, 219, 223, 0.2); } }

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
  animation: dot-typing 1s infinite linear; }

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff; }
  16.667% {
    box-shadow: 9984px -10px 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff; }
  33.333% {
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff; }
  50% {
    box-shadow: 9984px 0 0 0 #fff, 9999px -10px 0 0 #fff, 10014px 0 0 0 #fff; }
  66.667% {
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff; }
  83.333% {
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px -10px 0 0 #fff; }
  100% {
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff; } }
